import React from "react"
import { Helmet } from "react-helmet"
import config from "../../../site.config"

/** components */
import Layout from "../../components/Layout"
import { theme } from "../../components/Theme"

/** dynamic sections */
import HeroTopSection from "../../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../../components/dynamic-sections/RibbonSection"
import AwardsSection from "../../components/dynamic-sections/AwardsSection"
import TimelineNorhartSection from "../../components/dynamic-sections/TimelineNorhartSection"
import SixBoxSection from "../../components/dynamic-sections/SixBoxSection"
import TwoImageSection from "../../components/dynamic-sections/TwoImageSection"
import HeroBottomSection from "../../components/dynamic-sections/HeroBottomSection"
import VideoCommunitySection from "../../components/dynamic-sections/VideoCommunitySection"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** svg */
import NorhartIcon from "../../../assets/norhart-icon.svg"
import ApartmentIcon from "../../../assets/apartment-icon.svg"
import ValuesIcon from "../../../assets/values.svg"
import ValuesNorhartIcon from "../../../assets/value-norhart-logo.svg"
import ValuesRespectIcon from "../../../assets/value-complete-respect.svg"
import ValuesResultsIcon from "../../../assets/value-pursuit-results.svg"
import ValuesAttitudeIcon from "../../../assets/value-attitude.svg"
import ValuesMindsetIcon from "../../../assets/value-mindset.svg"
import ValuesReliableIcon from "../../../assets/value-reliable.svg"
import VideoIcon from "../../../assets/video-icon.svg"
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  data: any
}

/** const */
const Norhart: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/history/norhart/",
          name: "Norhart Story and History | Norhart",
          image: `${config.siteMetadata.siteUrl}/history/norhart/norhart-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Norhart Story | Norhart"
      description="Norhart, a compelling apartment startup success story that started with the Kaeding family dream to build quality apartments in the Minneapolis/ St. Paul metro area and create a better way for people to live."
      keywords="norhart, luxury, innovative, smart, technology, apartment, apartments, building, buildings, mn, minnesota, minneapolis, st paul, rent, rentals, resident, residents, forest lake, blaine, circle pines, lexington, coon rapids, Oakdale, encore apartments, lexington lofts apartments, Oakdale apartments, gateway green apartments, mill pond apartments, birchview apartments, legacy pointe apartments, heritage heights apartments, springwood apartments, greystone apartments, northbrook apartments, construction, norhart construction, lean construction"
      imageTwitter={`${config.siteMetadata.siteUrl}/history/norhart/norhart-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/history/norhart/norhart-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Norhart Story"
        subtitle="How We Got Our Start"
        imageTitle="How We Got Our Start"
        image="/history/norhart/norhart-history-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title=" Norhart's Startup Journey"
        tagLine="Our vision is gaining traction"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Story"
        title="Norhart From Inception To Success"
        subTitle="The Norhart story from inception to developing momentum and finally bringing the dream to the ground with traction! The future looks bright for #TeamNorhart! We are excited about the journey ahead!"
        image="/history/norhart/norhart-team.png"
        imageAlt="Norhart Support Team"
        textColor={colorPalette.textColor}
        asoEffect="fade-in"
      />

      <VideoCommunitySection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        icon={VideoIcon}
        iconTitle="Video"
        title="How We Got Our Start"
        subtitle="Mike Kaeding, Norhart's CEO, talks about this family-owned business and the passing of this beloved father."
        colorPalette={colorPalette}
        youtubeUrl="https://www.youtube.com/embed/dEVkGauGXDo"
      />

      <TimelineNorhartSection
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Timeline"
        title="The Norhart Journey"
        subtitle="The Norhart journey begins with Ed and Brenda Kaeding's dream to produce affordable multi-family housing and the desire to change people's lives in the process."
      />

      <TwoImageSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        icon={ApartmentIcon}
        iconTitle="Norhart Roadmap"
        title="The Norhart Vision"
        subtitle={`Our "Prime Directive" is to build exceptional apartments that create a better way for people to live. We are hyper-focused on creating a best-in-class customer experience for our residents.`}
        imageTitleOne="Our Vision and Roadmap"
        imageOneText="Our 10-year roadmap simply is to be the largest smart apartment developer in the Greater Minneapolis/St. Paul area with a focus on lifestyle living, building community, and giving back."
        imageOne="/about/norhart-about-vision.webp"
        imageOneAlt="Norhart Vision and Roadmap"
        imageOnePill=""
        imageTitleTwo="Building Traction"
        imageTwoText="Norhart is bringing our vision to the ground by hiring top-notch employees, analyzing market data, becoming strong problem solvers, and defining processes to optimize our business."
        imageTwo="/about/norhart-about-traction.webp"
        imageTwoAlt="Norhart Traction"
        imageTwoPill=""
        pillActive={true}
        colorPalette={colorPalette}
        buttonName=""
        buttonUrl=""
      />

      <SixBoxSection
        backgroundColorOne="#EFF2F4"
        backgroundColorTwo="#EFF2F4"
        boxIconFive={ValuesMindsetIcon}
        boxIconFour={ValuesAttitudeIcon}
        boxIconOne={ValuesNorhartIcon}
        boxIconSix={ValuesReliableIcon}
        boxIconThree={ValuesResultsIcon}
        boxIconTwo={ValuesRespectIcon}
        boxTitleOne="Norhart"
        boxSubTitleOne="This Is The Way"
        boxDescriptionOne="At Norhart, we hire, work, and live by our values. Our values are what defines and drives our culture at Norhart."
        boxTitleTwo="Value"
        boxSubTitleTwo="Achieve Great Things"
        boxDescriptionTwo="We lead from the front, love our work, we were born to do it; we accomplish far more than we thought possible."
        boxTitleThree="Value"
        boxSubTitleThree="Improve Every Day"
        boxDescriptionThree="We question the status quo;  We believe work shouldn't be complicated. We relentlessly simplify and automate our processes."
        boxTitleFour="Value"
        boxSubTitleFour="Be A Genuine Human"
        boxDescriptionFour="We are kind, supportive, and humble. We share credit, admit our mistakes and learn from them. We are open, honest, and vulnerable."
        boxTitleFive="Value"
        boxSubTitleFive="Resident Experience Obsession"
        boxDescriptionFive="We fight to make every resident touchpoint memorable and frictionless. We care about every little detail and its impact."
        boxTitleSix="Value"
        boxSubTitleSix="Level Yourself Up"
        boxDescriptionSix="We want to be the best in the world at what we do. We want to be so much better than the competition that the fight isn't fair."
        buttonColor="#34a5ff"
        buttonName="View #NorhartCulture"
        buttonText="#FFFFFF"
        buttonUrl="/norhart-life/"
        colorPalette={colorPalette}
        icon={ValuesIcon}
        subtitle="Knowing your team and company have your back is a great feeling! We are continually expanding our reach and setting our standards exceptionally high to elevate our organization and our people."
        title="Culture of Values"
      />

      <AwardsSection
        header="Norhart Apartments"
        title="Yes! We Build More Than Apartments"
        subtitle="We have been called out for being an innovator, setting the bar high, and pushing the industry in a new direction. That's just who we are. Experience the Norhart difference for yourself!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default Norhart
