import React from "react"

/** props */
interface Props {
  icon: any
  iconTitle: string
  title: string
  subtitle: string
}

/** const */
const TimelineNorhartSection: React.FC<Props> = (props) => {
  const Icon = props.icon

  return (
    <section
      id="norhart"
      className="container-fluid pt-5 pb-0"
      style={{ background: "linear-gradient(#EFF2F4, #EFF2F4)", color: "#365c70" }}
    >
      <div className="container pb-5">
        <div className="text-center pb-2">
          <Icon />
        </div>
        <div
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl"
          style={{ fontSize: "15px", textAlign: "center", color: "#333333" }}
        >
          {props.iconTitle}
        </div>
        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line"
          style={{ borderColor: "rgba(0, 0, 0, 0.2)", marginTop: "10px", marginBottom: "10px" }}
        />
        <h3 className="contentHeroTitle" style={{ color: "#365c70" }}>
          {props.title}
        </h3>
        <h4 className="contentHeroSubTitle" style={{ color: "#365c70", opacity: "0.8" }}>
          {props.subtitle}
        </h4>
      </div>

      <div id="timeline">
        <div className="history-card-wrapper">
          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>01</span>
              </div>
              <h2>
                <span className="small">1988: Ed and Brenda Kaeding</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/norhart/ed-brenda.png" alt="" />
              </div>
              <p>
                Our company started with just one man, Ed Kaeding. Growing up on the family farm near Devils Lake, ND,
                Ed acquired many valuable skills including those related to building and construction. During his
                college years, he helped his brother with new home construction, eventually moving on to apartment
                construction. Ed had a desire to build quality homes.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>02</span>
              </div>
              <h2>
                <span className="small">1990: Moved From North Dakota</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/norhart/north-dakota.png" alt="" />
              </div>
              <p>
                Ed and his wife, Brenda, owned 20 rental units in North Dakota and decided to move the operations to
                Forest Lake, MN.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>03</span>
              </div>
              <h2>
                <span className="small">1996: NorthBrook Apartments</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/norhart/northbrook.png" alt="" />
              </div>
              <p>
                Together they built the first 8-plex building. They maintained a steady pace of construction with 90
                units completed. Their sons, Michael and Lucas, were also involved as part of construction teams.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>04</span>
              </div>
              <h2>
                <span className="small">1997: Greystone Apartments</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/norhart/greystone.png" alt="" />
              </div>
              <p>
                Since the 8-plex was such a big hit, the team moved in a direction of making bigger apartments to reach
                more people. The next project had 10 units.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>05</span>
              </div>
              <h2>
                <span className="small">1999: Springwood Apartments</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/norhart/springwood.png" alt="" />
              </div>
              <p>
                During this year, the team was busy. Not only did they build an 18-unit apartment building, but they
                also built a Duplex, to rent out to families in need.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>06</span>
              </div>
              <h2>
                <span className="small">1999: 256 Duplex Forest Lake</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/norhart/256-duplex.png" alt="" />
              </div>
              <p>256 Duplex</p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>07</span>
              </div>
              <h2>
                <span className="small">2000: Kaeding Family</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/norhart/kaeding-family.png" alt="" />
              </div>
              <p>
                When the housing market was on fire Ed realized this was a poor time to invest in more apartments and
                wisely focused on paying down debt to weather the future storm he saw coming. After the crash in 2008
                the small company was well positioned for further growth.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>08</span>
              </div>
              <h2>
                <span className="small">2002: Maple Court Apartments</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/norhart/maple-court.png" alt="" />
              </div>
              <p>The next project had 12 units.</p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>09</span>
              </div>
              <h2>
                <span className="small">2003: Mill Pond Forest Apartments</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/norhart/mill-pond-forest.png" alt="" />
              </div>
              <p>
                A year later, behind Maple Court, their next project had 30 units. Unlike the previous buildings, this
                building offered underground parking, a workout room, and a commons area.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>10</span>
              </div>
              <h2>
                <span className="small">2004: Birchview Apartments</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/norhart/birchview.png" alt="" />
              </div>
              <p>8 units.</p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>11</span>
              </div>
              <h2>
                <span className="small">2007: A1 Storage</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/norhart/a1-storage.png" alt="" />
              </div>
              <p>
                Up the road, off of Highway 8 in Forest Lake, the company acquired a 190 unit storage facility, A1 Mini
                Storage.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>12</span>
              </div>
              <h2>
                <span className="small">2007: 607 House Forest Lake</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/norhart/607-house.png" alt="" />
              </div>
              <p>
                In the same year, the company acquired two houses. Both conveniently located in the heart of Forest
                Lake. Designed with 2 bedrooms and 1 bathroom is the 607 House.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>13</span>
              </div>
              <h2>
                <span className="small">2007: 555 House Forest Lake</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/norhart/555-house.png" alt="" />
              </div>
              <p>The second house, known as the 555 House, is a 3 bedroom 1.5 bathroom.</p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>14</span>
              </div>
              <h2>
                <span className="small">2010: 489 House Forest Lake</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/norhart/489-house.png" alt="" />
              </div>
              <p>The company bought another house known as the, 489 House. Composed of 3 bedrooms and 2 bathrooms.</p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>15</span>
              </div>
              <h2>
                <span className="small">2013: Mill Pond Apartments</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/norhart/mill-pond.png" alt="" />
              </div>
              <p>
                During this time, their son Mike and his wife Alyssa began more significant roles in the development of
                the company. The four made plans to begin a 120-unit complex to be built in four phases. This was a very
                aggressive undertaking for the small company. Their dream and consistent hard work paid off with the
                completion of this large complex.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>16</span>
              </div>
              <h2>
                <span className="small">2015: 243 House Forest Lake</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/norhart/243-house.png" alt="" />
              </div>
              <p>
                Aside from the 3 bedroom, 1 bathroom 243 House that the company aquired, came the idea for building
                townhomes.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>17</span>
              </div>
              <h2>
                <span className="small">2015: Gateway Green Townhomes</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/norhart/gateway-green-townhomes.png" alt="" />
              </div>
              <p>During this same year, we constructed our 8 unit townhomes.</p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>18</span>
              </div>
              <h2>
                <span className="small">2016: Emberwood Apartments</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/norhart/emberwood.png" alt="" />
              </div>
              <p>
                To get back on track, the company decided to specialize in one area, which was apartments. No longer was
                the company concerned about storage facilities, homes, or townhouses. The company extended out of Forest
                Lake and built Emberwood Apartments in the city of Blaine. This property would soon excel the company in
                a new direction to create a better way for people to live with new featured amenities like a game room,
                movie room, community room, and more!
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>19</span>
              </div>
              <h2>
                <span className="small">2018: Emberwood Apartments</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/norhart/emberwood.png" alt="" />
              </div>
              <p>
                During this year, our company moved in a more innovative direction. Our Blaine apartment became one of
                the first smart apartments in Minnesota.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>20</span>
              </div>
              <h2>
                <span className="small">2018: Score Small Business Award</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/norhart/asbc-award.png" alt="" />
              </div>
              <p>
                We were recognized as an American Small Business Champion by SCORE and received awards from Best in Biz
                for Local Company of the Year. We are grateful for the support that we have received as we work towards
                creating a better way for people to live.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>21</span>
              </div>
              <h2>
                <span className="small">2019: Gateway Green Apartments</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/norhart/gateway-green.png" alt="" />
              </div>
              <p>
                The first community in Forest Lake of Norhart's to offer one bedrooms opened May 1st, 2019. Between the
                two buildings, they offer an additional 84 units, a pet spa, fitness center, community room, and an
                underground parking garage.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>22</span>
              </div>
              <h2>
                <span className="small">2020: Encore Apartments</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/norhart/encore.png" alt="" />
              </div>
              <p>
                After two decades of working in what was technically a studio apartment at our Mill Pond Forest
                Apartments, the office got relocated! Just south of Mill Pond Forest is Encore Apartments. Encore was
                constructed and opened during the 2020 Coronavirus Pandemic. Encore is a nod to Ed who started our
                business and the new team that poured their blood, sweat, and tears into making it happen. Encore is our
                first property in Forest Lake to offer an outdoor pool, dog park, and movie room.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>23</span>
              </div>
              <h2>
                <span className="small">2021: Lexington Lofts Apartments</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/norhart/lexington-lofts.png" alt="" />
              </div>
              <p>
                It’ll take three years, but we will be doubling the population of Lexington. Lexington Lofts is a two
                building apartment in the shape of an “L.” It is the tallest apartment that we have built (so far). From
                the community room on the fifth floor, just overlooking the trees, you can see the skyscrapers from
                downtown. This is the biggest apartment, to date, that we have built.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
/** export */
export default TimelineNorhartSection
