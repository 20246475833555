import React from "react"
import { ColorPalette } from "../Theme"

interface Props {
  icon: any
  iconTitle: string
  title: string
  subtitle: string
  buttonUrl?: string
  colorPalette: ColorPalette
  backgroundColorOne: string
  backgroundColorTwo: string
  youtubeUrl: string
}

const VideoCommunitySection: React.FC<Props> = (props) => {
  const Icon = props.icon

  return (
    <section
      className="container-fluid text-center pt-5 pb-5"
      style={{
        background: `linear-gradient(${props.backgroundColorOne} 30%, ${props.backgroundColorTwo} 70%)`,
        color: "#FFFFFF",
      }}
    >
      <a id="NavVideo"></a>
      <div className="container pl-3 pr-3">
        <div className="container pb-5"></div>
        <div className="text-center pb-2">
          <Icon />
        </div>
        <div className="hidden-sm hidden-md hidden-lg hidden-xl">
          <strong>{props.iconTitle}</strong>
        </div>

        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line mb-3 mt-3"
          style={{ borderColor: "rgba(0, 0, 0, 0.2)" }}
        />
        <h3 className="contentHeroTitle" style={{ color: "#FFFFFF" }}>
          {props.title}
        </h3>
        <h4 className="contentHeroSubTitle mt-0 mb-5" style={{ color: "#FFFFFF", opacity: "0.6" }}>
          {props.subtitle}
        </h4>

        <div className="" style={{ height: "500px" }}>
          <iframe
            style={{ width: "90%", height: "100%" }}
            src={props.youtubeUrl}
            frameBorder="0"
            allow="encrypted-media"
            allowFullScreen
            title="Norhart Video"
            loading="lazy"
          />
        </div>
      </div>
    </section>
  )
}
/** export */
export default VideoCommunitySection
